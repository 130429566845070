.ag-theme-quartz .ag-details-row,
.ag-theme-quartz-dark .ag-details-row,
.ag-theme-quartz-auto-dark .ag-details-row {
  padding: 0;
}

.collapse-container::-webkit-scrollbar {
  display: none;
}

.ag-row:hover {
  background: #242428 !important;
}

.ag-row[aria-expanded='true'] {
  background: #242428 !important;
}

.ag-full-width-container .ag-row {
  background: #242428 !important;
}

.ag-full-width-container .ag-row {
  max-height: 300px !important;
  overflow-y: auto;
}

.ag-group-contracted .ag-icon,
.ag-group-expanded .ag-icon {
  position: absolute !important;
  left: 20% !important;
}

@keyframes fadeOut {
  0% {
    background: #30f0d626;
  }
  100% {
    background: #171719;
  }
}

.ag-cell-value {
  font-size: 14px !important;
  font-family: Nunito !important;
  font-style: normal !important;
  font-weight: 500 !important;
}

.ag-menu-list {
  background-color: #242428 !important;
  border-radius: 4px;
}

div[role='rowgroup'] div[role='row'] {
  overflow: hidden;
}

.react-resizable-handle,
.react-resizable-handle-se {
  position: relative;
  margin-top: 40px;
  top: 100%;
}

.react-grid-item > .react-resizable-handle::after {
  border-right-color: aliceblue !important;
  border-bottom-color: aliceblue !important;
}
.resize-handle {
  width: 20px; /* רוחב האייקון */
  height: 20px; /* גובה האייקון */
  position: absolute; /* מאפשר למקם אותו על גבי הרכיב */
  right: 5px; /* מרחק מהקצה הימני */
  bottom: 5px; /* מרחק מהקצה התחתון */
  cursor: nwse-resize; /* מצביע שינוי גובה ורוחב */
  background-color: rgba(0, 0, 0, 0.1); /* צבע רקע חצי שקוף */
  border-radius: 3px; /* פינות מעוגלות לאייקון */
}
.rates-only-grid .ag-header-cell-text {
  display: flex;
  justify-content: start;
  margin-left: 80px;
}

.ag-full-width-container .ag-row {
  max-height: none !important;
}
