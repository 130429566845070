html,
body,
#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  /* height: 100dvh;
  width: 100dvw;
  min-width: 100dvw;
  max-width: 100dvw; */
  display: flex;
  flex-direction: column;
  background-color: #171719;
  font-family: 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

footer {
  flex-shrink: 0;
  position: relative;
  bottom: 0;
  margin-left: 4rem;
  padding: 16px 12px;
  border-top: 1px solid #39393c;
  background-color: #171719;
  width: -webkit-fill-available;
}
body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-track {
  background: #39393c;
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb {
  background: #707079;
  border-radius: 4px;
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #707079;
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb:horizontal {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-thumb:vertical {
  width: 6px;
  height: 6px;
}

.container {
  overflow-y: auto;
  margin-right: 8px;
}

.container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.container::-webkit-scrollbar-track {
  background: #39393c;
  width: 6px;
  height: 6px;
}

.container::-webkit-scrollbar-thumb {
  background: #707079;
  border-radius: 4px;
  width: 6px;
  height: 6px;
}

.MuiPaper-root::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.MuiPaper-root::-webkit-scrollbar-track {
  background: #39393c !important;
  width: 6px;
  height: 6px;
}

.MuiPaper-root::-webkit-scrollbar-thumb {
  background: #707079 !important;
  border-radius: 4px !important;
  width: 6px;
  height: 6px;
}

.MuiDialogActions-root {
  border-top: none;
  background-color: #373737;
}

.MuiInputBase-root {
  background-color: #39393c;
}

li[aria-selected='true'] {
  color: #9999ff !important;
  background-color: transparent !important;
}

li[aria-selected='true']:hover {
  color: #9999ff !important;
  background-color: #242428 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #39393c inset !important;
  background-color: #39393c !important;
  padding: 0;
  color: white !important;
  caret-color: white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input,
button,
.MuiInputBase-root {
  overflow: hidden !important;
  height: 32px !important;
  font-family: 'Nunito' !important;
}

label {
  font-family: 'Nunito' !important;
}

input,
.MuiSelect-select,
.MuiOutlinedInput-root {
  padding: 11px, 8px !important;
}

button {
  padding: 11px 24px;
}

.ag-cell,
.MuiTypography-root {
  font-family: 'Nunito' !important;
}

.MuiInputBase-root {
  height: 32px !important;
  overflow: hidden !important;
  border: none;
}

.MuiAutocomplete-root {
  overflow: hidden !important;
  height: 32px !important;
  max-height: 32px !important;
}

.MuiFormControl-root > .Mui-disabled {
  background-color: #242428 !important;
  -webkit-text-fill-color: #fff !important;
  border: 1px solid #707079 !important;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
  background: #39393c;
  width: 8px;
  height: 8px;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
  background: #707079;
  border-radius: 4px;
  width: 8px;
  height: 8px;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #707079;
  width: 8px;
  height: 8px;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:horizontal {
  width: 8px;
  height: 8px;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:vertical {
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  background: #39393c;
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background: #707079;
  border-radius: 4px;
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #707079;
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:horizontal {
  width: 8px;
  height: 8px;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:vertical {
  width: 8px;
  height: 8px;
}

.ag-body-vertical-scroll-viewport {
  width: 8px !important;
  max-width: 8px !important;
  min-width: 8px !important;
}

.ag-body-vertical-scroll {
  width: 8px !important;
  max-width: 8px !important;
  min-width: 8px !important;
}

.ag-body-horizontal-scroll {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
}

.ag-body-horizontal-scroll-viewport {
  height: 8px !important;
  min-height: 8px !important;
  max-height: 8px !important;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #fff !important;
}

.MuiSelect-select.MuiSelect-outlined.Mui-disabled {
  -webkit-text-fill-color: #fff !important;
}

.MuiInputBase-input-MuiOutlinedInput-input,
.MuiAutocomplete-input {
  height: 32px !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.Mui-disabled svg {
  fill: #707079 !important;
}

.Mui-disabled svg path {
  fill: #707079 !important;
}

.modal input::placeholder {
  color: grey;
  opacity: 1;
  font-family: 'Nunito';
  font-size: 16px;
}

.MuiList-root {
  background-color: #373737;
}
