.ag-header-cell-text {
  color: #707079;
  display: flex;
  flex: 1;
  padding: 12px !important;
  align-items: center;
  justify-content: center;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

.ag-header-row,
.ag-header-row-column {
  border-radius: 16px 0px 0px 0px;
  background: #000;
}

.ag-center-cols-viewport,
.ag-row {
  background: #171719;
}

.ag-theme-quartz,
.ag-theme-quartz-dark,
.ag-theme-quartz-auto-dark {
  --ag-active-color: #39393c;
}

.ag-cell {
  text-align: center;
  padding-top: auto;
  padding-bottom: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-overlay-no-rows-center {
  font-size: 18px;
}

.ag-header-row,
.ag-header-row-column,
.ag-root-wrapper {
  border-radius: 0px;
}

.ag-header-row,
.ag-header-row-column {
  background-color: #171719;
  height: 41px;
}

.ag-root-wrapper,
.ag-header,
.ag-advanced-filter-header {
  border-color: #2f2f30;
}

.ag-header-cell-comp-wrapper .MuiBox-root .MuiTypography-root{
  text-transform: uppercase;
}