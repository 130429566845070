.SearchTextField::placeholder {
  color: #707079;
}

.SearchTextField {
  background: #39393c !important;
  border-radius: 4px !important;
  text-align: center;
}

.SearchTextField input {
  padding-left: 0px !important;
  padding-right: 8px !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
  border-radius: 4px !important;
  font-family: Nunito !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.SearchTextField .MuiInputBase-root {
  height: 32px !important;
  padding: 8px !important;
  border-radius: 4px !important;
}

.SearchTextField:hover {
  color: none !important;
}

.SearchIcon {
  fill: #9999ff !important;
}
